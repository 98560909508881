import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import { Helmet, HelmetProvider } from 'react-helmet-async'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Homepage from './pages/Homepage'

const theme = createTheme({})

const App = () => {
  return (
    <HelmetProvider>
      <CssBaseline />
      <Helmet>
        <title>Masao Codes</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="//*" element={<Homepage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
